import Footer from "../components/Footer";
import HelmetHeader from "../components/HelmetHeader";
import Navbar from "../components/Navbar";

const Policy = () => {
  return (
    <div className="body-inner">
       <HelmetHeader
        title={`Política de Privacidade`}
        description="Tem alguma dúvida? Consulte esse documento."
        url={`politica`}
      />
      <Navbar />
      <section className="main-container">
        <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Política de Privacidade</h1>
            <p>
              Este site é mantido e operado por V Silva e Silva LTDA. Nós
              coletamos e utilizamos alguns dados pessoais que pertencem àqueles
              que utilizam nosso site. Ao fazê-lo, agimos na qualidade de
              <strong>controlador</strong> desses dados e estamos sujeitos às
              disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção
              de Dados Pessoais - LGPD). Nós cuidamos da proteção de seus dados
              pessoais e, por isso, disponibilizamos esta política de
              privacidade, que contém informações importantes sobre:
            </p>
            <ul>
              <li>Quem deve utilizar nosso site</li>
              <li>Quais dados coletamos e o que fazemos com eles;</li>
              <li>Seus direitos em relação aos seus dados pessoais; e</li>
              <li>Como entrar em contato conosco</li>
            </ul>
            <h2>1. Dados que coletamos e motivos da coleta</h2>
            <p>
              Nosso site coleta e utiliza alguns dados pessoais de nossos
              usuários, de acordo com o disposto nesta seção.
            </p>
            <i>1. Dados pessoais fornecidos expressamente pelo usuário</i>
            <p>
              Nós coletamos os seguintes dados pessoais que nossos usuários nos
              fornecem expressamente ao utilizar nosso site:
            </p>
            <ul>
              <li>Nome Completo;</li>
              <li>Número Telefone;</li>
              <li>CEP;</li>
              <li>Consumo Mensal de Energia Elétrica;</li>
              <li>E-Mail.</li>
            </ul>
            <p>A coleta destes dados ocorre nos seguintes momentos:</p>
            <ul>
              <li>Quando o usuário utiliza o formulário de contato;</li>
              <li>Quando o usuário faz uma compra</li>
              <li>
                Quando o usuário entra em contato com a empresa por terceiros.
              </li>
              <li>Quando o usuário faz login por intermédio do Google.</li>
              <li>
                Quando o usuário preenche e envia seus dados a partir do
                dashboard.
              </li>
            </ul>
            <p>
              Os dados fornecidos por nossos usuários são coletados com as
              seguintes finalidades:
            </p>
            <ul>
              <li>
                Para que o usuário possa receber um orçamento personalizado;
              </li>
              <li>Para que o orçamento tenha precisão;</li>
              <li>Para que o usuário possa entrar em contato com a empresa.</li>
              <li>Para que o usuário possa usufruir das funções do sistema</li>
            </ul>
            <i>2. Dados sensíveis</i>
            <p>
              <strong>Não</strong> serão coletados dados sensíveis de nossos
              usuários, assim entendidos aqueles definidos nos arts. 11 e
              seguintes da Lei de Proteção de Dados Pessoais. Assim,
              <strong>não</strong> haverá coleta de dados sobre origem racial ou
              étnica, convicção religiosa, opinião política, filiação a
              sindicato ou a organização de caráter religioso, filosófico ou
              político, dado referente à saúde ou à vida sexual, dado genético
              ou biométrico, quando vinculado a uma pessoa natural.
            </p>
            <i>3. Coleta de dados não previstos expressamente</i>
            <p>
              Eventualmente, outros tipos de dados não previstos expressamente
              nesta Política de Privacidade poderão ser coletados, desde que
              sejam fornecidos com o consentimento do usuário, ou, ainda, que a
              coleta seja permitida com fundamento em outra base legal prevista
              em lei.
            </p>
            <p>
              Em qualquer caso, a coleta de dados e as atividades de tratamento
              dela decorrentes serão informadas aos usuários do site.
            </p>
            <h2>2. Compartilhamento de dados pessoais com terceiros</h2>
            <p>
              Nós não compartilhamos seus dados pessoais com terceiros. Apesar
              disso, é possível que o façamos para cumprir alguma determinação
              legal ou regulatória, ou, ainda, para cumprir alguma ordem
              expedida por autoridade pública.
            </p>
            <h2>3. Por quanto tempo seus dados pessoais serão armazenados</h2>
            <p>
              Os dados pessoais coletados pelo site são armazenados e utilizados
              por período de tempo que corresponda ao necessário para atingir as
              finalidades elencadas neste documento e que considere os direitos
              de seus titulares, os direitos do controlador do site e as
              disposições legais ou regulatórias aplicáveis.
            </p>
            <p>
              Uma vez expirados os períodos de armazenamento dos dados pessoais,
              eles são removidos de nossas bases de dados ou anonimizados, salvo
              nos casos em que houver a possibilidade ou a necessidade de
              armazenamento em virtude de disposição legal ou regulatória.
            </p>
            <h2>4. Bases legais para o tratamento de dados pessoais</h2>
            <p>
              Cada operação de tratamento de dados pessoais precisa ter um
              fundamento jurídico, ou seja, uma base legal, que nada mais é que
              uma justificativa que a autorize, prevista na Lei Geral de
              Proteção de Dados Pessoais.
            </p>
            <p>
              Todas as Nossas atividades de tratamento de dados pessoais possuem
              uma base legal que as fundamenta, dentre as permitidas pela
              legislação. Mais informações sobre as bases legais que utilizamos
              para operações de tratamento de dados pessoais específicas podem
              ser obtidas a partir de nossos canais de contato, informados ao
              final desta Política.
            </p>
            <i>1. Como o titular pode exercer seus direitos</i>
            <p>
              Os titulares de dados pessoais tratados por nós poderão exercer
              seus direitos por meio do formulário disponibilizado no seguinte
              caminho: https://www.dlwalt.com/ajuda. Alternativamente, se
              desejar, o titular poderá enviar um e-mail ou uma correspondência
              ao nosso Encarregado de Proteção de Dados Pessoais. As informações
              necessárias para isso estão na seção "Como entrar em contato
              conosco" desta Política de Privacidade.
            </p>
            <p>
              Os titulares de dados pessoais tratados por nós poderão exercer
              seus direitos a partir do envio de mensagem ao nosso Encarregado
              de Proteção de Dados Pessoais, seja por e-mail ou por
              correspondência. As informações necessárias para isso estão na
              seção "Como entrar em contato conosco" desta Política de
              Privacidade.
            </p>
            <p>
              Para garantir que o usuário que pretende exercer seus direitos é,
              de fato, o titular dos dados pessoais objeto da requisição,
              poderemos solicitar documentos ou outras informações que possam
              auxiliar em sua correta identificação, a fim de resguardar nossos
              direitos e os direitos de terceiros. Isto somente será feito,
              porém, se for absolutamente necessário, e o requerente receberá
              todas as informações relacionadas.
            </p>
            <h2>6. Medidas de segurança no tratamento de dados pessoais</h2>
            <p>
              Empregamos medidas técnicas e organizativas aptas a proteger os
              dados pessoais de acessos não autorizados e de situações de
              destruição, perda, extravio ou alteração desses dados.
            </p>
            <p>
              As medidas que utilizamos levam em consideração a natureza dos
              dados, o contexto e a finalidade do tratamento, os riscos que uma
              eventual violação geraria para os direitos e liberdades do
              usuário, e os padrões atualmente empregados no mercado por
              empresas semelhantes à nossa.
            </p>
            <p>
              Entre as medidas de segurança adotadas por nós, destacamos as
              seguintes:
            </p>
            <ul>
              <li>
                Os dados dos usuários são armazenados em um banco de dados local
                com sincronia em nuvem, oferecido pelos serviços do Google, onde
                apenas a equipe da empresa têm acesso.
              </li>
            </ul>
            <p>
              Ainda que adote tudo o que está ao seu alcance para evitar
              incidentes de segurança, é possível que ocorra algum problema
              motivado exclusivamente por um terceiro - como em caso de ataques
              de hackers ou crackers ou, ainda, em caso de culpa exclusiva do
              usuário, que ocorre, por exemplo, quando ele mesmo transfere seus
              dados a terceiro. Assim, embora sejamos, em geral, responsáveis
              pelos dados pessoais que tratamos, nos eximimos de
              responsabilidade caso ocorra uma situação excepcional como essas,
              sobre as quais não temos nenhum tipo de controle.
            </p>
            <p>
              De qualquer forma, caso ocorra qualquer tipo de incidente de
              segurança que possa gerar risco ou dano relevante para qualquer de
              nossos usuários, comunicaremos os afetados e a Autoridade Nacional
              de Proteção de Dados acerca do ocorrido, em conformidade com o
              disposto na Lei Geral de Proteção de Dados.
            </p>
            <h2>8. Alterações nesta política</h2>
            <p>
              A presente versão desta Política de Privacidade foi atualizada
              pela última vez em: 21/11/2022
            </p>
            <p>
              Reservamo-nos o direito de modificar, a qualquer momento, as
              presentes normas, especialmente para adaptá-las às eventuais
              alterações feitas em nosso site, seja pela disponibilização de
              novas funcionalidades, seja pela supressão ou modificação daquelas
              já existentes.
            </p>
            <p>
              Sempre que houver uma modificação, nossos usuários serão
              notificados acerca da mudança.
            </p>
            <h2>9. Como entrar em contato conosco</h2>
            <p>
              Para esclarecer quaisquer dúvidas sobre esta Política de
              Privacidade ou sobre os dados pessoais que tratamos, entre em
              contato com nosso Encarregado de Proteção de Dados Pessoais, por
              algum dos canais mencionados abaixo:
            </p>
            <ul>
              <li>E-Mail: contato@dlwalt.net</li>
              <li>Telefone: +55 68 9240-2096</li>
              <li>
                Endereço postal: Av. Ceará, 2042 - Bosque - Rio Branco - AC CEP
                69900-379
              </li>
            </ul>
          </div>
        </div>
      </div>

        </section>
      <Footer />
    </div>
  );
};

export default Policy;
